




































import { Component, Vue } from 'vue-property-decorator';
import Player from '@/components/Player.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

interface PlayerInterface {
  name: string;
  visible: boolean;
  owned: boolean;
}

@Component({
  components: {
    Player,
  },
})
export default class Leaderboard extends Vue {
  players: Array<PlayerInterface> = [];

  mounted() {
    this.axios.get(`${process.env.VUE_APP_OKOCHA_URL}profile/list/`)
      .then((response) => {
        response.data.results.map((result: PlayerInterface) => {
          const newResult = result;
          newResult.visible = false;
          return newResult;
        });
        this.players = response.data.results;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleItsMe(index: number) {
    const newPlayer = this.players[index];
    if (!newPlayer.owned) {
      newPlayer.visible = !newPlayer.visible;
    }
    this.$set(this.players, index, newPlayer);
  }
}
