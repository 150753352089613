


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag';
import ScoreBlock from './ScoreBlock.vue';

@Component({
  components: {
    ScoreBlock,
    CountryFlag,
  },
})
export default class Player extends Vue {
  @Prop() private player!: unknown;
}
